module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-instagram/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f7cc4bca94a0459593e58a0b4209a839@o205718.ingest.sentry.io/5420996","sampleRate":0.4},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TRN4TNJ"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SALTO KS | Cloud-Based Smart Locks, Wireless Access Control","short_name":"SALTO KS","start_url":"/","background_color":"#FFF","theme_color":"#0088ce","display":"standalone","icon":"static/salto-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"85ffd1ce275dbb0e7bcbde2a548fcc4b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
